<template>
  <div v-bind:class="{ animateProgressBar: animate }" class="container">
    <ion-progress-bar v-bind="$attrs" :value="finalvalue"></ion-progress-bar>
    <!-- <div
      v-bind:class="{ animateTranslate: animate, animateScale: animate }"
      class="progress-bar-child"
    ></div> -->
    <div class="avatar-container" v-if="avatar">
      <ion-avatar>
        <img :src="avatar" alt="" />
      </ion-avatar>
    </div>
  </div>
</template>

<script>
import { IonAvatar, IonProgressBar } from "@ionic/vue"

export default {
  name: "GradientProgressBar",
  components: { "ion-progress-bar": IonProgressBar, IonAvatar },
  inheritAttrs: false, // optional
  props: ["value", "avatar"],
  data() {
    return {
      progress: 0,
      progressperc: 0,
      animate: true,
    }
  },
  methods: {},
  created() {
    this.finalvalue = 1 - this.value
    const that = this
    setTimeout(() => {
      // that.progress = Math.min(1,this.value+0.1);
      that.progress = 0
      that.progressperc = 100 - 100 * that.progress + "%"
    }, 5)
    setTimeout(() => {
      that.progress = 1 - this.value
      that.progressperc = 100 - 100 * that.progress + "%"
    }, 800)
  },
}
</script>
<style scoped>
ion-progress-bar {
  transform: rotate(180deg);
  margin-top: 4px;
}
.avatar-container {
  overflow: hidden;
  position: absolute;
  top: -30px;
  width: calc(100% - 60px);
  z-index: 1000;
  transform: translate3d(0, 0, 0) translateZ(1000px);
}
ion-avatar {
  position: relative;
  height: 60px;
  width: 60px;
}
.animateProgressBar .avatar-container {
  transform: translate(v-bind("progressperc"), 0);
  transition: transform 0.8s cubic-bezier(0.2, 0.5, 0.1, 1);
}
.animateProgressBar ion-progress-bar::part(progress) {
  transform: scaleX(v-bind("progress"));
  /*transition: transform 0.5s linear 0s, -webkit-transform 0.5s linear 0s;*/
  transition: transform 0.8s cubic-bezier(0.2, 0.5, 0.1, 1);
}
</style>
